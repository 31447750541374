/* eslint-disable no-unreachable */
/* eslint-disable react/display-name */
import React, { useState, useRef, useEffect } from "react";
import Scheduler from "./scheduler";
import "../../lib/scheduler/scheduler.stockholm.min.css";
import styled from "styled-components";
import { DateHelper } from "bryntum-scheduler";
import useCalendarEvents from "../../hooks/use-calendar-events";
import { Link } from "react-router-dom";
import CreateEventModal from "../documents/create-event-modal";
import EditEventModal from "../documents/edit-event-modal";
import useAsync from "../../hooks/use-async";
import { useAuth } from "../../providers/auth-provider";
import apiClient from "../../api/api-client";
import useDocumentLinkTypes from "../../hooks/use-document-link-types";
import { toast } from "react-toastify";
import { format } from "date-fns";
import RemoveDocumentModal from "../documents/remove-document-modal";
import Spinner from "../ui/spinner";

export default function Calendar({ xId, yId, module, category, space, headerDocumentId, columns = [], colors = [], colorField = null, bgField = null, onlyEditUserId = false }) {
    const { user } = useAuth();
    const [startDate, setStartDate] = useState(() => new Date());
    const [endDate, setEndDate] = useState(() => {
        const end = new Date();
        end.setDate(end.getDate() + 30);
        return end;
    });
    const schedulerRef = useRef();
    const { resources, events, isPending, reload: reloadEvents } = useCalendarEvents(startDate, endDate, xId, yId);
    const [editorOpen, setEditorOpen] = useState(false);
    const [editorXId, setEditorXId] = useState(null);
    const [editorStartDate, setEditorStartDate] = useState(null);
    const [editorEndDate, setEditorEndDate] = useState(null);
    const [editorEventId, setEditorEventId] = useState(null);
    const { run, isPending: isSaving } = useAsync();
    const [documentToRemove, setDocumentToRemove] = useState(null);
    const [sort, setSort] = useState(() => columns.filter(i => i?.type != "rownumber").map(i => ({
        documentTypeId: i?.type == "document" ? xId : i?.documentLinkTypeId,
        text: i?.text,
    })));
    const target = React.useRef(null);
    const { height, width } = useWindowSize(target);

    const resourcesList = resources?.map(r => {
        const name = r?.values?.find(i => i.name === "name")?.value;
        return { id: r?.id, name, links: r?.links || [], values: r?.values, };
    }) || [];

    const eventsList = events?.map(e => {
        const links = e?.links?.filter(i => i.document_type?.id === parseInt(xId));
        const headerLinks = e?.links?.filter(i => i.document_type?.id === headerDocumentId);
        const startDate = findValue(e, "start_date");
        const endDate = findValue(e, "end_date");
        const name = findValue(e, "name");
        const heading = headerLinks?.length > 0 ? findValue(headerLinks[0], "name") : undefined;

        return links?.map(l => {
            const resourceIndex = resourcesList.findIndex(i => i.id === l?.id);
            return {
                resourceId: l?.id || undefined,
                startDate,
                endDate,
                name: heading ? `${heading} (${name})` : name,
                eventColor: findColor(e, colors, colorField),
                eventId: e?.id,
                heading,
            };
        });
    })?.flat()?.filter(i => !!i) || [];

    const editEventDates = (eId, eStartDate, eEndDate) => {
        const start_date = format(eStartDate, "yyyy-MM-dd");
        const end_date = format(eEndDate, "yyyy-MM-dd");
        run(apiClient(`workflow/document/${eId}`, {
            method: "PATCH", data: {
                start_date,
                end_date,
            }
        }))
            .then(() => toast.success("Daty zostały zmienione"))
            .catch(error => {
                toast.error("Nie udało się zmienić dat: " + error?.message);
                reloadEvents();
            });
    };

    if (!user?.id || (isPending && resources?.length == 0)) {
        return <Spinner />;
    }

    return <Wrapper ref={target}>
        <Scheduler
            ref={schedulerRef}
            startDate={startDate}
            endDate={endDate}
            isLoading={isPending}
            height={height - 150}
            width={width - 350}
            columns={
                columns?.map(col => ({
                    type: col?.type === "rownumber" ? "rownumber" : null,
                    text: col?.text,
                    renderer: ({ record }) => recordRenderer(col, record, {
                        module: col?.module ?? module,
                        category: col?.category ?? category,
                        space: col?.space ?? space,
                        additionalColumn: col?.additionalColumn,
                        documentLinkTypeId: col?.documentLinkTypeId,
                    }),
                    sortable: (a, b) => {
                        if (col?.type == "document") {
                            return a?.data?.name?.localeCompare(b?.data?.name);
                        }

                        if (col?.type == "link") {
                            const aVal = a?.data?.links?.find(i => i?.document_type?.id === col?.documentLinkTypeId);
                            const aName = aVal?.values?.find(i => i.name === "name")?.value ?? "";

                            const bVal = b?.data?.links?.find(i => i?.document_type?.id === col?.documentLinkTypeId);
                            const bName = bVal?.values?.find(i => i.name === "name")?.value ?? "";

                            return aName?.localeCompare(bName);
                        }

                        return 0;
                    },
                    filterable: ({ record, value, operator }) => {
                        if (col?.type == "document") {
                            return record?.data?.name?.toLowerCase()?.includes(value?.toLowerCase());
                        }

                        if (col?.type == "link") {
                            const val = record?.data?.links?.find(i => i?.document_type?.id === col?.documentLinkTypeId);
                            const name = val?.values?.find(i => i.name === "name")?.value ?? "";

                            return name?.toLowerCase()?.includes(value?.toLowerCase());
                        }

                        return false;
                    }
                }))
            }
            resources={resourcesList}
            events={eventsList}
            emptyText="Brak rekordów."
            eventStyle="border"
            weekStartDay={1}
            zoomLevel={1}
            listeners={{
                beforeEventEdit({ eventRecord, resourceRecord }) {
                    if (onlyEditUserId && !ownsResource(resourceRecord?.id, resources, user?.id)) {
                        return false;
                    }

                    const _xId = resourceRecord?.id;
                    const _startDate = eventRecord?.startDate;
                    const _endDate = eventRecord?.endDate;
                    setEditorOpen(true);
                    setEditorXId(_xId);
                    setEditorStartDate(_startDate);
                    setEditorEndDate(_endDate);
                    setEditorEventId(eventRecord?.data?.eventId);
                    return false;
                },
                beforeEventDrag({ eventRecord }) {
                    if (onlyEditUserId && !ownsResource(eventRecord?.data?.resourceId, resources, user?.id)) {
                        return false;
                    }
                },
                afterEventDrop({ source, assignmentRecords, eventRecords, valid, context }) {
                    eventRecords?.forEach(record => {
                        editEventDates(record?.eventId, Date.parse(record?.startDate), Date.parse(record?.endDate));
                    });
                },
                beforeEventResize({ eventRecord }) {
                    if (onlyEditUserId && !ownsResource(eventRecord?.data?.resourceId, resources, user?.id)) {
                        return false;
                    }
                },
                eventResizeEnd({ wasChanged, eventRecord }) {
                    // if (!wasChanged) return;
                    editEventDates(eventRecord?.eventId, Date.parse(eventRecord?.startDate), Date.parse(eventRecord?.endDate));
                },
                beforeEventDelete({ eventRecords, resourceRecord }) {
                    if (eventRecords?.length == 0) return false;
                    const eventRecord = eventRecords[0];


                    if (onlyEditUserId && !ownsResource(eventRecord?.data?.resourceId, resources, user?.id)) {
                        return false;
                    }

                    setDocumentToRemove(eventRecord?.eventId);
                    return false;
                },
                timeAxisChange({ config: { startDate, endDate } }) {
                    setStartDate(startDate);
                    setEndDate(endDate);
                }
            }}
            stripeFeature={true}
            timeRangesFeature={{
                showCurrentTimeLine: true,
            }}
            nonWorkingTimeFeature={true}
            summaryFeature={{
                renderer: ({ events }) => events?.length || "",
            }}
            filterFeature={true}
            barMargin={7}
            viewPreset={{
                base: "weekAndDayLetter",
                headers: [{
                    unit: "week",
                    dateFormat: "MM.YYYY",
                    verticalColumnWidth: 115
                }, {
                    unit: "day",
                    dateFormat: "DD",
                    verticalColumnWidth: 25
                }]
            }}
            eventDragFeature={{
                constrainDragToResource: true
            }}
            tbar={[
                {
                    type: "date",
                    value: "up.startDate",
                    step: "1d",
                    onChange({ value }) {
                        // Preserve time, only changing "day"
                        const scheduler = schedulerRef.current.schedulerInstance;
                        const diff = DateHelper.diff(DateHelper.clearTime(scheduler.startDate), value, "days");
                        scheduler.startDate = DateHelper.add(scheduler.startDate, diff, "days");
                    }
                },
                "->",
                {
                    type: "buttongroup",
                    items: [
                        {
                            type: "button",
                            icon: "b-fa-angle-left",
                            tooltip: "View previous day",
                            onAction() {
                                schedulerRef.current.schedulerInstance.shiftPrevious();
                            }
                        },
                        {
                            type: "button",
                            ref: "todayButton",
                            text: "Today",
                            tooltip: "View today, to see the current time line",
                            onAction() {
                                const today = DateHelper.clearTime(new Date());
                                today.setHours(5);
                                schedulerRef.current.schedulerInstance.setTimeSpan(today, DateHelper.add(today, 30, "day"));
                            }
                        },
                        {
                            type: "button",
                            icon: "b-fa-angle-right",
                            tooltip: "View next day",
                            onAction() {
                                schedulerRef.current.schedulerInstance.shiftNext();
                            }
                        }
                    ]
                },
            ]}
        />

        {editorOpen && editorEventId && <EditEventModal
            documentTypeId={yId}
            documentLinkTypeId={xId}
            open={editorOpen}
            startDate={editorStartDate}
            endDate={editorEndDate}
            eventId={editorEventId}
            resourceId={editorXId}
            onClose={() => setEditorOpen(false)}
            onEdit={() => {
                setEditorOpen(false);
                reloadEvents();
                toast.success("Dokument został zapisany");
            }} />}

        {editorOpen && !editorEventId && <CreateEventModal
            documentTypeId={yId}
            documentLinkTypeId={xId}
            open={editorOpen}
            startDate={editorStartDate}
            endDate={editorEndDate}
            eventId={editorEventId}
            resourceId={editorXId}
            onClose={() => setEditorOpen(false)}
            onEdit={() => {
                setEditorOpen(false);
                reloadEvents();
                toast.success("Dokument został zapisany");
            }}
            onSubmit={e => {
                setEditorOpen(false);
                toast.info("Dokument został utworzony");
                reloadEvents();
            }} />}
        {documentToRemove && <RemoveDocumentModal document={{ id: documentToRemove }} onClose={() => setDocumentToRemove(false)} onRemoved={() => {
            reloadEvents();
            setDocumentToRemove(null);
        }} />}
    </Wrapper>;
}

function recordRenderer(col, record, { module, category, space, additionalColumn, documentLinkTypeId }) {
    if (col?.type === "document") {
        const additionalData = additionalColumn ? record?.values?.find(i => i.name === additionalColumn)?.value : null;

        return <div>
            <Link to={`/${module}/${category}/${space}/${record?.id}`}>{record?.name}</Link>
            {additionalData && <div>{additionalData}</div>}
        </div>;
    } else if (col?.type === "link") {
        const val = record?.links?.find(i => i?.document_type?.id === documentLinkTypeId);
        const name = val?.values?.find(i => i.name === "name")?.value;

        return <div>
            {val ? <Link to={`/${module}/${category}/${space}/${val.id}`}>{name}</Link> : "-"}
        </div>;
    }

    return null;
}

const Wrapper = styled.div`
    margin-top: 20px;
`;

function findValue(event, key) {
    if (!event?.values?.find) return null;
    return event?.values?.find(i => i.name === key)?.value;
}

function findColor(record, colors, colorColumn) {
    const value = record?.values?.find(i => i?.name == colorColumn);
    if (!value) return "blue";

    return colors[value?.value] ?? "blue";
}

function ownsResource(resourceId, resources, userId) {
    const resource = resources?.find(i => i?.id == resourceId);
    if (!resource) return false;

    const resourceOwnerId = resource?.values?.find(i => i?.name == "user_id")?.value;
    if (!resourceOwnerId) return false;

    return parseInt(resourceOwnerId) == userId;
}

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
}