/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import Form, { Field } from "@atlaskit/form";
import prepareFormData from "../../utils/prepare-form-data";
import styled from "styled-components";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import FormField from "../forms/form-field";
import SearchDocumentsByType from "./search-documents-by-type";
import DocumentTableFormFields from "./document-table-form-fields";
import { useTranslation } from "react-i18next";
import toBoolean from "../../utils/to-boolean";
import QuestionCircleIcon from "@atlaskit/icon/glyph/question-circle";
import Tooltip from "@atlaskit/tooltip";
import InlineEdit from "../forms/inline-edit";
import apiClient from "../../api/api-client";
import useDocument from "../../hooks/use-document";
import Spinner from "../ui/spinner";
import useAsync from "../../hooks/use-async";
import { toast } from "react-toastify";

export default function DocumentTableEditRecord({ document, record, open, onClose, onSubmit, fields, document_links = [], editable, columns = [], onUpdated, documentTypeId, linkTypeId = 3, root = null }) {
    const [filters, setFilters] = useState(
        record?.links?.reduce((acc, item) => {
            const dtId = item?.document_type?.id;
            const name = document_links?.find(i => i?.document_type_id == dtId)?.name;
            if (name) acc[name] = {
                value: item?.id,
            };
            return acc;
        }, {})
    );
    const [links, setLinks] = useState(
        record?.links?.reduce((acc, item) => {
            const dtId = item?.document_type?.id;
            const name = document_links?.find(i => i?.document_type_id == dtId)?.name;
            if (name) acc[name] = {
                value: item?.id,
            };
            return acc;
        }, {})
    );
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const { run, isPending } = useAsync();
    const [isWorking, setIsWorking] = useState(false);

    useEffect(() => {
        setData(record.values ?? {});
    }, [record.id]);

    useEffect(() => {
        if (editable) return;

        setFilters({});
        setLinks({});
    }, [editable]);

    const save = (fieldName, saveValue) => {
        apiClient(`workflow/document/${record.id}`, {
            method: "PATCH",
            data: {
                [fieldName]: saveValue
            }
        })
            .then(() => {
                setData((prev) => ({
                    ...prev,
                    [fieldName]: saveValue
                }));
            })
            .catch(() => toast.error(t("inline_edit_error")));
    };

    const onFieldChanged = (fieldName, value) => {
        setData((prev) => ({
            ...prev,
            [fieldName]: value
        }));
        fields?.map(field => {
            let column = {};

            try {
                column = JSON.parse(field?.options);
            } catch (err) {
                // console.log(err);
            }

            if (!column?.computed) return;
            const isA = column?.computed?.a === fieldName;
            const isB = column?.computed?.b === fieldName;

            if (!(isA || isB)) return;

            const aValue = isA ? value : data[column?.computed?.a];
            const bValue = isB ? value : data[column?.computed?.b];
            const op = column?.computed?.op;

            if (!aValue || !bValue || !op) return;

            let newValue;
            switch (op) {
                case "mult":
                    newValue = Number(aValue) * Number(bValue);
                    break;
                default:
                    return;
            }

            save(field?.name, newValue);
        });
    };

    const unlinkDocument = (id) => {
        if (!id) return Promise.resolve();
        return Promise.all([
            apiClient(`document/${record?.id}/unlink/${id}`, { method: "DELETE" }),
            apiClient(`document/${id}/unlink/${record?.id}`, { method: "DELETE" }),
        ]);
    };

    const linkDocument = (id) => {
        if (!id) return Promise.resolve();
        return apiClient(`document/${record?.id}/link/${id}/type/${linkTypeId}`, { method: "POST" });
    };

    return <>
        <ModalTransition>
            {open && (
                <Modal
                    onClose={() => {
                        onClose();
                    }}
                    heading={t("document_table_edit_record_heading")}
                    scrollBehavior="outside"
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEscapePress={false}
                >
                    <Wrapper>
                        {fields
                            .filter(i => !toBoolean(i.hidden))
                            .sort((a, b) => a.position - b.position).map(field => {
                                if (field.field.type === "section") {
                                    return null;
                                }

                                const value = data[field.name];
                                const inputProps = {
                                    key: field.id,
                                    name: field.name,
                                    isRequired: (!!field.required || !!field?.must_have) && !toBoolean(field.read_only),
                                    defaultValue: field?.field?.type === "checkbox" ? toBoolean(data[field.name]) : data[field.name],
                                    isInvalid: false,
                                    table_column: field?.table_column || [],
                                    isDisabled: toBoolean(field?.read_only ?? 0),
                                    rawValues: document?.rawValues || [],
                                    autoFocus: false
                                };

                                const help = field?.help?.length > 0 &&
                                    <HelpWrapper>
                                        <Tooltip content={field?.help}>
                                            <QuestionCircleIcon size="small" />
                                        </Tooltip>
                                    </HelpWrapper>;

                                return <Row key={field.id}>
                                    {field?.field?.type === "checkbox"
                                        || field?.field?.type === "table" ? null : <Label>
                                        <Wrapper>
                                            {field.label} {help}
                                        </Wrapper></Label>}
                                    <Value>
                                        <InlineEdit value={value} document={record} root={root} field={field} fields={fields} inputProps={inputProps} editable={true} onSaved={(val) => {
                                            onFieldChanged(field?.name, val);
                                            // onUpdated && onUpdated();
                                        }} />
                                    </Value>
                                </Row>;
                            })}
                        <div>
                            {document_links?.map(link => (<div key={link?.id}>
                                {link?.type === "filter" && <FieldWrapper>
                                    <Field label={link?.label} name={link?.name}>
                                        {({ fieldProps }) => {
                                            return <SearchDocumentsByType
                                                value={filters ? filters[link?.name] || null : null}
                                                selectedDocumentId={filters ? filters[link?.name]?.value || null : null}
                                                selectedDocumentTypeId={link?.document_type_id}
                                                typeId={link?.document_type_id}
                                                isDisabled={isWorking}
                                                document={document}
                                                onChange={e => {
                                                    setFilters(f => ({ ...f, [link?.name]: e }));
                                                }} />;
                                        }}
                                    </Field>
                                </FieldWrapper>}
                                {link?.type === "link" && <FieldWrapper>
                                    <Field label={link?.label} name={link?.name}
                                        isRequired={link?.required === "true" || link?.required === true}>
                                        {({ fieldProps }) => {
                                            return <SearchDocumentsByType
                                                value={links ? links[link?.name] || null : null}
                                                selectedDocumentId={links ? links[link?.name]?.value || null : null}
                                                selectedDocumentTypeId={link?.document_type_id}
                                                typeId={link?.document_type_id}
                                                filter={filters && filters[link?.filtered_by]}
                                                link={link || null}
                                                document={document}
                                                isDisabled={isPending || isWorking}
                                                onChange={e => {
                                                    const oldOne = links[link?.name]?.value;
                                                    const newOne = e?.value;

                                                    setIsWorking(true);

                                                    Promise.all([
                                                        unlinkDocument(oldOne),
                                                        linkDocument(newOne),
                                                    ]).then(() => {
                                                        setLinks(l => ({ ...l, [link?.name]: e }));
                                                    })
                                                        .catch(() => toast.error(t("unexpected_error")))
                                                        .finally(() => setIsWorking(false));
                                                }} />;
                                        }}
                                    </Field>
                                </FieldWrapper>}
                            </div>))}
                        </div>
                        <Spacer />
                        <ButtonGroup>
                            <Button type="button" onClick={() => onClose()} isDisabled={isWorking}>
                                {t("document_table_form_fields_close")}
                            </Button>
                        </ButtonGroup >
                        <Spacer />
                    </Wrapper>
                </Modal>
            )}
        </ModalTransition>
    </>;
}

const Wrapper = styled.div`
    margin-bottom: 20px;
`;

const FieldWrapper = styled.div`
    margin-top: 20px;
`;

const HelpWrapper = styled.div`
    padding: 0 5px 0;
    cursor: help;
`;

const Row = styled.div`
`;

const Value = styled.div`
    box-sizing: border-box;
    display: block;
    max-width: 100%;
    width: auto;
    margin-bottom: 30px;
    font-size: 1.0rem;
`;

const Label = styled.label`
font-size: 1em;
font-style: inherit;
font-weight: 600;
letter-spacing: -0.003em;
margin-top: 16px;
line-height: 24px;
color: #6B778C;
overflow-wrap: break-word;
min-width: 0px;
`;

const Spacer = styled.div`
    height: 20px;
`;
