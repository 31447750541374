import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useDocument, { useDocumentWithoutDt } from "../../hooks/use-document";
import styled from "styled-components";
import Page16Icon from "@atlaskit/icon-object/glyph/page/16";
import slugify from "slugify";
import useDocumentsList from "../../hooks/use-documents-list";
import Spinner from "../ui/spinner";

export default function DisplayDocumentByType({ id, documentTypeId, margin = true, height = "30px", tempName = "-" }) {
    const { isPending, document } = useDocumentWithoutDt(id);

    if (isPending && id) return <Spinner />;
    if (!id) return <div>-</div>;

    return <DocumentFrame height={height}>
        <DocumentLink document={document} margin={margin} height={height} />
    </DocumentFrame>;
}

export function DisplayDocumentsByType({ ids, documentTypeId, margin = true, height = "30px", tempName = "-", optionsData = {} }) {
    const [filters, setFilters] = useState(null);
    const { documents, reload: reloadDocuments, isPending } = useDocumentsList(filters && documentTypeId, 1, 10000, "id", 1, filters);

    useEffect(() => {
        if (!Array.isArray(ids)) {
            setFilters(null);
            return;
        }
        if (ids?.length == 0) {
            setFilters(null);
            return;
        }

        setFilters({
            type: "OR",
            filters: (ids ?? []).map(id => ({
                column: "id",
                operator: "is",
                value: id,
            })),
        });
    }, [ids]);

    if (isPending && ids?.length > 0) return <Spinner />;

    if (isPending || !documents || documents?.data?.length == 0 || ids?.length == 0 || !ids || !documents?.data) return <div>{tempName}</div>;

    return <>
        <DocumentFrame height={height} >
            <DocumentLinkWrapper>
                {documents?.data?.map(doc => doc?.values?.name)?.join(", ")}
            </DocumentLinkWrapper>
        </DocumentFrame >
    </>;
}


function DocumentLink({ document, margin, height }) {
    const space = document?.document_type?.space;
    if (!space) return null;
    const nameField = document?.document_type?.fields?.find(i => i.name === "name");
    const name = document?.values[nameField.id];
    if (!name) return null;
    const path = `${slugify(space?.category?.module?.name)}/${space?.category?.slug ?? slugify(space?.category?.name || "")}/${space?.slug}`.toLowerCase();
    return <DocumentLinkWrapper margin={margin}>
        <Link to={`/${path}/${document.id}`}>
            <DocumentLinkRow height={height}>
                {name}
            </DocumentLinkRow>
        </Link>
    </DocumentLinkWrapper>;
}


const DocumentLinkWrapper = styled.div`
    font-size: 14px;
    margin-bottom: ${props => props.margin ? "15px" : "0px"};
    margin-top: 5px;
`;

const DocumentLinkRow = styled.div`
    height: ${props => props.height};
    display: flex;
    font-weight: 600;
    align-items: center;
`;

const DocumentLinkIcon = styled.div`
    width: 16px;
    margin-right: 5px;
`;

const DocumentFrame = styled.div`
    display: inline-block;
    width: auto;
    height: ${props => props.height};
    padding: 2px 5px;
    margin: 0px 0;
    overflow: hidden;
    position: relative;
`;