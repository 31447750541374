import React, { useEffect, useState } from "react";
import { Gantt } from "./index.tsx";
import "gantt-task-react/dist/index.css";
import useDocumentsList from "../../hooks/use-documents-list";
import Spinner from "../ui/spinner";
import i18next from "i18next";
import { format } from "date-fns";
import apiClient from "../../api/api-client";
import useAsync from "../../hooks/use-async";
import { toast } from "react-toastify";
import EditEventModal from "../documents/edit-event-modal";
import Select from "@atlaskit/select";
import Drawer from "../ui/drawer";
import styles from "./task-list-header.module.css";

let hidden, visibilityChange;

if (typeof document.hidden !== "undefined") {
    hidden = "hidden";
    visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
}


export default function GanttView({ documentTypeId, space, module, category, projectTypeId, projectsSlug }) {
    const { isPending, documents, reload, fields } = useDocumentsList(documentTypeId, 1, 10000, "id", 1);
    const { isPending: isPendingProjects, documents: documentsProjects, reload: reloadProjects } = useDocumentsList(projectTypeId, 1, 10000, "id", 1);
    const [locale, setLocale] = React.useState(i18next.language?.slice(0, 2) ?? "en");
    const { run, isPending: isSaving } = useAsync();
    const [tasks, setTasks] = useState([]);
    const [toEdit, setToEdit] = useState(null);
    const [view, setView] = useState("Week");
    const [drawerId, setDrawerId] = useState(null);
    const [drawerSlug, setDrawerSlug] = useState(space);
    const [sidebarVisible, setSidebarVisible] = useState(() => {
        const value = localStorage.getItem("menubar.visible");
        if (!value) return true;
        return value === "true";
    });
    const [visibilityStatus, setVisibilityStatus] = React.useState(document[hidden]);

    useEffect(() => {
        const interval = setInterval(() => {
            let value = localStorage.getItem("menubar.visible");
            if (!value) value = "true";
            setSidebarVisible(value === "true");
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    React.useEffect(() => {
        function handleVisibilityChange() {
            setVisibilityStatus(document[hidden]);

            reload();
            reloadProjects();
        }

        document.addEventListener(visibilityChange, handleVisibilityChange);

        return () => {
            document.removeEventListener(visibilityChange, handleVisibilityChange);
        };
    }, []);

    useEffect(() => {
        if (isPending == true) return;
        if (isPendingProjects == true) return;

        const newProjects = documentsProjects?.data?.map(i => {
            let newTask = {
                id: "project_" + parseInt(i?.id),
                originalId: parseInt(i?.id),
                name: i?.name,
                start: setHours(new Date(Date.parse(i?.values?.start ?? new Date()))),
                end: setHours(new Date(Date.parse(i?.values?.end ?? new Date()))),
                progress: parseInt(i?.values?.progress ?? 0) ?? 0,
                type: "project",
                // dependencies: i?.links?.filter(l => l?.link_type_id == 7)?.map(l => l?.id) ?? [],
                hideChildren: false,
                displayOrder: (1000 * parseInt(i?.id)),
            };

            return newTask;
        }) ?? [];

        const newDocs = documents?.data?.map(i => {
            let newTask = {
                id: parseInt(i?.id),
                name: i?.name,
                start: setHours(new Date(Date.parse(i?.values?.start ?? new Date()))),
                end: setHours(new Date(Date.parse(i?.values?.end ?? new Date()))),
                progress: parseInt(i?.values?.progress ?? 0) ?? 0,
                type: i?.values?.type ?? "task",
                dependencies: i?.links?.filter(l => l?.link_type_id == 7)?.map(l => l?.id) ?? [],
                hideChildren: false,
                displayOrder: (i?.values?.project ? (1000 * parseInt(i?.values.project) + 1) : 0),
            };

            if (i?.values?.project && newTask.type != "project") {
                newTask.project = "project_" + parseInt(i?.values?.project);
            }

            return newTask;
        }) ?? [];

        const foundProjects = newDocs?.map(i => i?.project ?? null);
        const visibleProjects = newProjects?.filter(p => foundProjects?.includes(p?.id));

        setTasks([
            ...visibleProjects,
            ...newDocs,
        ]);


    }, [isPending, isPendingProjects]);

    function onClick(task) {
        setDrawerId(task?.originalId ?? task.id);
        setDrawerSlug(task?.type == "project" ? projectsSlug : space);
    }

    function handleExpanderClick(task) {
        setTasks(tasks.map(t => (t.id === task.id ? task : t)));
    }

    function onDateChange(task) {
        let newTasks = tasks.map(t => {
            if (t.id == task.id) {
                return task;
            }

            return t;
        });

        if (task.project) {
            const [start, end] = getStartEndDateForProject(newTasks, task.project);
            const project = newTasks[newTasks.findIndex(t => t.id === task.project)];
            if (
                project.start.getTime() !== start.getTime() ||
                project.end.getTime() !== end.getTime()
            ) {
                const changedProject = { ...project, start, end };
                newTasks = newTasks.map(t =>
                    t.id === task.project ? changedProject : t
                );
            }
        }

        setTasks(newTasks);

        if (task.project) {
            const project = newTasks[newTasks.findIndex(t => t.id === task.project)];
            const start_date = format(project.start, "yyyy-MM-dd");
            const end_date = format(project.end, "yyyy-MM-dd");

            run(apiClient(`workflow/document/${project?.originalId}`, {
                method: "PATCH", data: {
                    start: start_date,
                    end: end_date,
                }
            }))
                .catch(() => { })
                .finally(() => {
                    const start_date = format(task.start, "yyyy-MM-dd");
                    const end_date = format(task.end, "yyyy-MM-dd");

                    run(apiClient(`workflow/document/${task?.originalId ?? task.id}`, {
                        method: "PATCH", data: {
                            start: start_date,
                            end: end_date,
                        }
                    }))
                        .then(() => toast.success("Daty zostały zmienione"))
                        .catch(error => {
                            toast.error("Nie udało się zmienić dat: " + error?.message);
                        }).finally(() => {
                            reload();
                            reloadProjects();
                        });
                });

        } else {
            const start_date = format(task.start, "yyyy-MM-dd");
            const end_date = format(task.end, "yyyy-MM-dd");

            run(apiClient(`workflow/document/${task?.originalId ?? task.id}`, {
                method: "PATCH", data: {
                    start: start_date,
                    end: end_date,
                }
            }))
                .then(() => toast.success("Daty zostały zmienione"))
                .catch(error => {
                    toast.error("Nie udało się zmienić dat: " + error?.message);
                }).finally(() => {
                    reload();
                    reloadProjects();
                });
        }

    }

    function onProgressChange(task) {
        setTasks(allTasks => allTasks.map(t => {
            if (t.id == task.id) {
                return task;
            }

            return t;
        }));


        run(apiClient(`workflow/document/${task?.originalId ?? task.id}`, {
            method: "PATCH", data: {
                progress: task.progress,
            }
        }))
            .then(() => toast.success("Postęp został zaktualizowany"))
            .catch(error => {
                toast.error("Nie udało się zaktualizować postępu: " + error?.message);
                reload();
                reloadProjects();
            });
    }

    if (tasks?.length == 0) {
        return <EmptyView />;
    }

    const fieldsMap = fields?.reduce((acc, i) => {
        acc[i?.name] = i?.label;
        return acc;
    }, {});

    return <div style={{ maxWidth: `calc(100vw - ${sidebarVisible ? "320px" : "90px"})`, opacity: isPending ? "0.5" : "1.0", overflowX: "auto" }}>
        <div style={{ display: "flex", marginBottom: "10px" }}>
            <div style={{ flex: 1 }}></div>
            <div style={{ width: "200px" }}>
                <Select

                    options={[
                        { label: "Dzień", value: "Day" },
                        { label: "Tydzień", value: "Week" },
                        { label: "Miesiąc", value: "Month" },
                        { label: "Rok", value: "Year" },
                    ]}
                    value={{
                        label: valueText(view),
                        value: view,
                    }}
                    onChange={e => setView(e.value)}
                />
            </div>
        </div>
        <Gantt
            tasks={tasks}
            locale={locale}
            onDateChange={onDateChange}
            onProgressChange={onProgressChange}
            onDoubleClick={onClick}
            onExpanderClick={handleExpanderClick}
            viewMode={view}
            TaskListHeader={props => <TaskListHeaderDefault fieldsMap={fieldsMap} headerHeight={props.headerHeight} fontFamily={props.fontFamily} fontSize={props.fontSize} rowWidth={props.rowWidth} />}
        />
        {toEdit && <EditEventModal
            documentTypeId={documentTypeId}
            documentLinkTypeId={documentTypeId}
            open={true}
            startDate={toEdit.start}
            endDate={toEdit.end}
            eventId={toEdit.id}
            resourceId={documentTypeId}
            onClose={() => setToEdit(false)}
            onEdit={() => {
                setToEdit(false);
                reload();
                reloadProjects();
                toast.success("Dokument został zapisany");
            }} />}
        {drawerId && <Drawer id={drawerId} space={drawerSlug ?? space} module={module} category={category} onReload={() => {
            reload();
            reloadProjects();
        }} onClose={() => {
            setDrawerId(null);
            reload();
            reloadProjects();
        }} />}

    </div>;
}


const EmptyView = () => {
    return <div></div>;
};

function setHours(date) {
    date.setHours(12);
    return date;
}

function valueText(value) {
    switch (value) {
        case "Week":
            return "Tydzień";
        case "Day":
            return "Dzień";
        case "Month":
            return "Miesiąc";
        case "Year":
            return "Rok";
    }
}

function getStartEndDateForProject(tasks, projectId) {
    const projectTasks = tasks.filter(t => t.project === projectId);
    let start = projectTasks?.[0]?.start ?? new Date();
    let end = projectTasks?.[0]?.end ?? new Date();

    for (let i = 0; i < projectTasks.length; i++) {
        const task = projectTasks[i];
        if (start.getTime() > task.start.getTime()) {
            start = task.start;
        }
        if (end.getTime() < task.end.getTime()) {
            end = task.end;
        }
    }
    return [start, end];
}


const TaskListHeaderDefault = ({ headerHeight, fontFamily, fontSize, rowWidth, fieldsMap }) => {
    return (
        <div
            className={styles.ganttTable}
            style={{
                fontFamily: fontFamily,
                fontSize: fontSize,
            }}
        >
            <div
                className={styles.ganttTable_Header}
                style={{
                    height: headerHeight - 2,
                }}
            >
                <div
                    className={styles.ganttTable_HeaderItem}
                    style={{
                        minWidth: rowWidth,
                    }}
                >
                    &nbsp;{fieldsMap["name"] ?? "-"}
                </div>
                <div
                    className={styles.ganttTable_HeaderSeparator}
                    style={{
                        height: headerHeight * 0.5,
                        marginTop: headerHeight * 0.2,
                    }}
                />
                <div
                    className={styles.ganttTable_HeaderItem}
                    style={{
                        minWidth: rowWidth,
                    }}
                >
                    &nbsp;{fieldsMap["start"] ?? "-"}
                </div>
                <div
                    className={styles.ganttTable_HeaderSeparator}
                    style={{
                        height: headerHeight * 0.5,
                        marginTop: headerHeight * 0.25,
                    }}
                />
                <div
                    className={styles.ganttTable_HeaderItem}
                    style={{
                        minWidth: rowWidth,
                    }}
                >
                    &nbsp;{fieldsMap["end"] ?? "-"}
                </div>
            </div>
        </div>
    );
};