import React, { useEffect, useState } from "react";
import PageHeader from "@atlaskit/page-header";
import AdminLayout from "../../layouts/admin-layout";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import useConfig from "../../hooks/use-config";
import TextArea from "@atlaskit/textarea";
import { Field } from "@atlaskit/form";
import Select from "@atlaskit/select";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/ui/spinner";
import DynamicTable from "@atlaskit/dynamic-table";
import CreateOrganization from "../../components/organizations/create-organization";
import EditOrganization from "../../components/organizations/edit-organization";
import DeleteOrganization from "../../components/organizations/delete-organization";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";

function AdminOrganizations() {
	const { t } = useTranslation();
	const { run, isPending, data } = useAsync();
	const [open, setOpen] = useState(false);
	const [toRemove, setToRemove] = useState(null);
	const [toEdit, setToEdit] = useState(null);

	useEffect(() => {
		reload();
	}, []);

	const reload = () => {
		run(apiClient("organizations"))
			.catch(() => {
				toast.error(t("admin_organizations_error"));
			});
	};

	if (isPending) {
		return <AdminLayout><Spinner /></AdminLayout>;
	}

	const head = {
		cells: [
			{ key: "id", content: "ID", width: "50px" },
			{ key: "name", content: t("admin_organizations_column_name"), isSortable: false },
		]
	};

	const rows = data?.data?.map(organization => ({
		key: organization.id,
		cells: [
			{ key: "id", content: <span>{organization.id}</span> },
			{
				key: "name", content: <span>{organization.name}</span>
			},
			{ key: "actions", content: null, isSortable: false },
			{
				key: "actions", content: <DropdownMenu position="bottom right" triggerType="button">
					<DropdownItemGroup>
						<DropdownItem onClick={() => setToEdit(organization)}>
							{t("admin_users_edit")}
						</DropdownItem>
						<DropdownItem onClick={() => setToRemove(organization)}>
							{t("admin_users_remove")}
						</DropdownItem>
					</DropdownItemGroup>
				</DropdownMenu>
			}
		],
	}));

	const actionsContent = (
		<ButtonGroup>
			<Button appearance="primary" onClick={() => setOpen(true)}>
				{t("admin_organizations_new")}
			</Button>
		</ButtonGroup >
	);

	return <AdminLayout>
		<PageHeader actions={actionsContent}>
			{t("admin_organizations_heading")}
		</PageHeader>
		<DynamicTable
			head={head}
			rows={rows}
		/>
		{open && <CreateOrganization open={open} onClose={() => setOpen(false)} onAdded={() => reload()} />}
		{toEdit && <EditOrganization open={true} organization={toEdit} onClose={() => setToEdit(null)} onAdded={() => reload()} />}
		{toRemove && <DeleteOrganization organization={toRemove} onRemoved={() => {
			setToRemove(null);
			reload();
		}} onClose={() => setToRemove(null)} />}
	</AdminLayout>;
}

export default AdminOrganizations;
