import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import Select from "@atlaskit/select";
import TextField from "@atlaskit/textfield";
import { Checkbox } from "@atlaskit/checkbox";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import Button from "@atlaskit/button";
import SaveFilters from "./save-filters";
import SelectDocumentFields from "../documents/select-document-fields";
import SearchDocumentsByType from "../documents/search-documents-by-type";
import { useTranslation } from "react-i18next";
import SearchUsers from "../users/search-users";

function Filters({ documentTypeId, columns, onCancel, onAccept, onHide, initialFilters, initialType, statuses, initialLinks, sortColumn, sortDir, columnsVisible, saveFor = null }) {
    const [filters, setFilters] = useState([]);
    const [links, setLinks] = useState(() => initialLinks || []);
    const [type, setType] = useState(() => initialType || "AND");
    const { t } = useTranslation();

    useEffect(() => {
        if (!initialFilters && filters?.length == 0) {
            setFilters([{ id: uuidv4(), column: "name", operator: "CONTAINS", value: "", type: "text" }]);
            return;
        }
        setFilters(initialFilters);
    }, [initialFilters, documentTypeId]);

    const add = () => setFilters([...filters, { id: uuidv4(), column: "name", operator: "CONTAINS", value: "", type: "text" }]);
    const remove = (id) => setFilters(filters?.filter(i => i.id !== id));

    const changeColumn = (filter, columnName) => {
        setFilters(filters?.map(f => {
            if (f.id !== filter.id) return f;
            const column = columns.find(i => i.name == columnName);
            const columnType = columnTypesOptions[column.type];
            const defaultValue = column?.field?.type === "select" && column?.options?.length > 0 ? column.options[0]?.value : "";
            return {
                ...f,
                column: columnName,
                operator: columnType ? columnType[0]?.value : "IS",
                type: column?.type,
                value: defaultValue,
            };
        }));
    };

    const changeFilter = (filter, type) => {
        setFilters(filters?.map(f => {
            if (f.id !== filter.id) return f;
            return { ...f, operator: type };
        }));
    };

    const changeFilterValue = (filter, value) => {
        setFilters(filters?.map(f => {
            if (f.id !== filter.id) return f;
            return { ...f, value: value };
        }));
    };

    const changeLinkValue = (filter, value) => {
        setLinks(links.map(l => {
            if (l.id !== l.id) return l;
            return { ...l, value: value };
        }));
    };

    const columnTypesOptions = {
        text: [
            { name: t("filters_contains"), field: "text", value: "CONTAINS" },
            { name: t("filters_does_not_contain"), field: "text", value: "DOES_NOT_CONTAIN" },
            { name: t("filters_is"), field: "text", value: "IS" },
            { name: t("filters_is_not"), field: "text", value: "IS_NOT" },
            { name: t("filters_is_empty"), field: null, value: "IS_EMPTY" },
            { name: t("filters_is_not_empty"), field: null, value: "IS_NOT_EMPTY" },
        ],
        users: [
            { name: t("filters_contains"), field: "user", value: "CONTAINS" },
        ],
        textarea: [
            { name: t("filters_contains"), field: "text", value: "CONTAINS" },
            { name: t("filters_does_not_contain"), field: "text", value: "DOES_NOT_CONTAIN" },
            { name: t("filters_is"), field: "text", value: "IS" },
            { name: t("filters_is_not"), field: "text", value: "IS_NOT" },
            { name: t("filters_is_empty"), field: null, value: "IS_EMPTY" },
            { name: t("filters_is_not_empty"), field: null, value: "IS_NOT_EMPTY" },
        ],
        document_type: [
            { name: t("filters_is"), field: "document_type", value: "IS" },
        ],
        "document-type": [
            { name: t("filters_is"), field: "document-type", value: "IS" },
        ],
        number: [
            { name: t("filters_is_equal"), field: "number", value: "IS_EQUAL" },
            { name: t("filters_is_not_equal"), field: "number", value: "IS_NOT_EQUAL" },
        ],
        select: [
            { name: t("filters_contains"), field: "select", value: "CONTAINS" },
            { name: t("filters_does_not_contain"), field: "select", value: "DOES_NOT_CONTAIN" },
            { name: t("filters_is_empty"), field: null, value: "IS_EMPTY" },
            { name: t("filters_is_not_empty"), field: null, value: "IS_NOT_EMPTY" },
        ],
        status: [
            { name: t("filters_is"), field: "status", value: "IS" },
            { name: t("filters_is_not"), field: "status", value: "IS_NOT" },
        ],
        checkbox: [
            { name: t("filters_is"), field: "checkbox", value: "IS" },
            { name: t("filters_is_not"), field: "checkbox", value: "IS_NOT" },
        ],
        date: [
            { name: t("filters_is"), field: "date", value: "IS" },
            { name: t("filters_is_before"), field: "date", value: "IS_BEFORE" },
            { name: t("filters_is_after"), field: "date", value: "IS_AFTER" },
            { name: t("filters_is_empty"), field: null, value: "IS_EMPTY" },
            { name: t("filters_is_day_before_yesterday"), field: null, value: "BEFORE_YESTERDAY" },
            { name: t("filters_is_yesterday"), field: null, value: "YESTERDAY" },
            { name: t("filters_is_today"), field: null, value: "TODAY" },
            { name: t("filters_is_tomorrow"), field: null, value: "TOMORROW" },
            { name: t("filters_is_day_after_tomorrow"), field: null, value: "AFTER_TOMORROW" },
        ],
        wysywig: [
            { name: t("filters_contains"), field: "text", value: "CONTAINS" },
            { name: t("filters_does_not_contain"), field: "text", value: "DOES_NOT_CONTAIN" },
        ],
    };

    return <DropdownItemGroup>
        {filters?.map((filter, i) => {
            const filterColumn = filter.column;
            const column = columns.find(i => i.name == filterColumn);
            if (!column) return null;

            const columnType = columnTypesOptions[column.type];
            if (!columnType) return null;

            const selectedColumnTypeFilter = columnType.find(i => i.value === filter.operator);

            const isFirst = i == 0;
            const isSecond = i == 1;

            return <FilterRow key={filter.id}>
                {isFirst && <RightAlignedFilterColumn>{t("filters_where")}</RightAlignedFilterColumn>}
                {isSecond && <FilterColumn>
                    <Select onChange={e => setType(e.value)}
                        defaultValue={{ value: type, label: t("filters_" + type?.toLowerCase()) }}
                        options={[
                            { value: "AND", label: t("filters_and") },
                            { value: "OR", label: t("filters_or") },
                        ]} />
                </FilterColumn>}
                {!isFirst && !isSecond && <RightAlignedFilterColumn>{type}</RightAlignedFilterColumn>}
                <FilterColumn>
                    <Select onChange={e => changeColumn(filter, e.value)}
                        defaultValue={column || (columns.length > 0 ? { label: columns[0].label, value: columns[0].name } : null)}
                        options={columns.filter(c => !["list", "float", "table"].includes(c?.field?.type)).map(i => ({ label: i.label, value: i.name }))} />
                </FilterColumn>
                <FilterColumn>
                    <Select onChange={e => changeFilter(filter, e.value)}
                        value={
                            {
                                label: filter?.operator ? columnType.find(i => i.value.toUpperCase() === filter.operator.toUpperCase())?.name : columnType[0]?.name,
                                value: filter?.operator ? columnType.find(i => i.value.toUpperCase() === filter.operator.toUpperCase())?.value : columnType[0]?.value,
                            }
                        }
                        // isOptionSelected={i => filter.operator.toUpperCase() === i.value.toUpperCase()}
                        options={columnType.map(i => ({ label: i.name, value: i.value }))} />
                </FilterColumn>
                <LastColumn>
                    {(!selectedColumnTypeFilter || !selectedColumnTypeFilter.field) ? null : <>
                        {(
                            selectedColumnTypeFilter.field == "text" || selectedColumnTypeFilter.field == "textarea"
                        ) && <TextField errorRow={false} type="text" value={filter.value} onChange={e => changeFilterValue(filter, e.target.value)} />}
                        {selectedColumnTypeFilter.field == "document_type" && <SearchDocumentsByType
                            typeId={filter?.column}
                            value={filter?.value}
                            selectedDocumentId={filter?.value?.value}
                            selectedDocumentTypeId={filter?.column}
                            onChange={e => changeFilterValue(filter, e)} />}
                        {selectedColumnTypeFilter.field == "document-type" && <SearchDocumentsByType
                            typeId={getDocumentTypeId(column)}
                            value={filter?.value}
                            selectedDocumentId={filter?.value}
                            selectedDocumentTypeId={getDocumentTypeId(column)}
                            onChange={e => changeFilterValue(filter, e?.value)} />}
                        {selectedColumnTypeFilter.field == "user" && <SearchUsers onChange={e => changeFilterValue(filter, e)} isMulti={false} selectedUser={filter?.value} />}
                        {selectedColumnTypeFilter.field == "number" && <TextField errorRow={false} type="number" value={filter.value} onChange={e => changeFilterValue(filter, e.target.value)} />}
                        {selectedColumnTypeFilter.field == "select" && <Select onChange={e => changeFilterValue(filter, e.value)}
                            defaultValue={selectValue(column, filter)}
                            options={column.options.map(i => ({ label: i.name, value: i.value }))} />}
                        {selectedColumnTypeFilter.field == "status" && <Select onChange={e => changeFilterValue(filter, e.value)}
                            options={statuses.map(i => ({ label: i.status, value: i.status }))} />}
                        {selectedColumnTypeFilter.field == "checkbox" && <Centered>
                            <Checkbox isChecked={filter.value} onChange={() => changeFilterValue(filter, !filter.value)} />
                        </Centered>}
                        {selectedColumnTypeFilter.field == "date" && <FilterRow>
                            <FilterColumn>
                                <Select onChange={e => {
                                    const selected = column.options.find(o => o.value == e.value);
                                    if (selected) return changeFilterValue(filter, e.value);
                                    return changeFilterValue(filter, null);
                                }}
                                    defaultValue={{ label: t("filters_exact"), value: "exact" }}
                                    options={[
                                        ...column.options.map(i => ({ label: i.name, value: i.value })),
                                        { label: t("filters_exact"), value: "exact", selected: !column.options.map(o => o.value).includes(filter.value) }
                                    ]} />
                            </FilterColumn>
                            {!column.options.map(o => o.value).includes(filter.value) && <div>
                                <FilterColumn>
                                    <TextField errorRow={false} type="date" value={filter.value} onChange={e => changeFilterValue(filter, e.target.value)} />
                                </FilterColumn>
                            </div>}
                        </FilterRow>}
                    </>}
                </LastColumn>
                <DotsWrapper>
                    <DropdownMenu position="bottom right" triggerType="button">
                        <DropdownItemGroup>
                            <DropdownItem onClick={() => remove(filter.id)}>
                                {t("filters_remove")}
                            </DropdownItem>
                        </DropdownItemGroup>
                    </DropdownMenu>
                </DotsWrapper>
            </FilterRow>;
        })}
        <ButtonsWrapper>
            <ButtonWrapper>
                <Button appearance="primary" variant="secondary" width="auto" onClick={add}>+ {t("filters_add")}</Button>
            </ButtonWrapper>
            <ButtonWrapper>
                <SaveFilters documentTypeId={documentTypeId} filters={filters} type={type}
                    sortColumn={sortColumn}
                    sortDir={sortDir}
                    columnsVisible={columnsVisible}
                    saveFor={saveFor}
                    onSuccess={(newFilterId) => {
                        onAccept && onAccept({ filters, type, newFilterId });
                        onHide && onHide();
                    }} />
            </ButtonWrapper>
            <Spacer />
            <ButtonWrapper>
                <Button type="secondary" variant="secondary" width="auto" onClick={onCancel}>
                    {t("filters_cancel")}
                </Button>
            </ButtonWrapper>
            <ButtonWrapper>
                <Button appearance="primary"
                    width="auto"
                    disabled={filters?.length === 0}
                    onClick={() => {
                        onAccept && onAccept({ filters, type });
                        onHide && onHide();
                    }}>
                    {t("filters_filter_results")}
                </Button>
            </ButtonWrapper>
        </ButtonsWrapper>
    </DropdownItemGroup>;
}

function selectValue(column, filter) {
    const selectedOption = column?.options?.find(i => i?.value === filter?.value);

    if (selectedOption) {
        return { label: selectedOption.name, value: selectedOption.value };
    }

    return column.options.length > 0 ? { label: column.options[0].name, value: column.options[0].value } : null;
}

export default Filters;

function getDocumentTypeId(filter) {
    try {
        const options = JSON.parse(filter?.options);
        return options?.documentTypeId;
    } catch (e) {
        return null;
    }
}

export const FilterRow = styled.div`
    display: flex;
    padding: 0px;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-width: 500px;
`;

export const Centered = styled.div`
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
`;

export const FilterColumn = styled.div`
    padding: 5px 10px;
    width: 100%;
`;

export const RightAlignedFilterColumn = styled(FilterColumn)`
    text-align: right;
    padding-right: 10px;
    font-weight: bold;
    text-transform: uppercase;
`;

export const LastColumn = styled(FilterColumn)`
    width: 150%;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-top: 10px;
    padding: 10px;
`;

export const ButtonWrapper = styled.div`
    margin-right: 15px;
`;

export const Spacer = styled.div`
    flex-grow: 1;
`;

export const DotsWrapper = styled.div`
    width: 100px;
    text-align: left;
`;

export const DotsIconWrapper = styled.div`
    cursor: pointer;
    padding: 10px 10px;
`;