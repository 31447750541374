import { useEffect, useCallback } from "react";
import apiClient from "../api/api-client";
import useAsync from "../hooks/use-async";
import documentFieldsResponse from "../api/document-fields-response";
import documentResponse from "../api/document-response";

export default function useDocument(id, documentTypeId) {
    const { run, isError, isSuccess, isIdle, isPending, data, error } = useAsync();
    const fields = useAsync();

    useEffect(() => {
        if (!id) return;
        run(apiClient(`workflow/document/${id}`)).catch(() => { });
    }, [id]);

    useEffect(() => {
        if (!documentTypeId) return;
        fields.run(apiClient(`document-types/${documentTypeId}`)).catch(() => { });
    }, [documentTypeId]);

    const reload = useCallback(() => {
        if (!id) return;
        run(apiClient(`workflow/document/${id}`)).catch(() => { });
    }, [id]);

    return {
        document: data ? documentResponse(data) : null,
        fields: fields.data ? documentFieldsResponse(fields.data) : [],
        isPending: isIdle || isPending || fields.isIdle || fields.isPending,
        isError: isError || fields.isError,
        isSuccess: isSuccess && fields.isSuccess,
        reload,
        error,
        forbidden: error?.status === 403 || fields?.error?.status === 403,
    };
}

export function useDocumentWithoutDt(id) {
    const { run, isError, isSuccess, isIdle, isPending, data, error } = useAsync();
    const fields = useAsync();

    useEffect(() => {
        if (!id) return;
        run(apiClient(`workflow/document/${id}`)).catch(() => { });
    }, [id]);

    return {
        document: data ? documentResponse(data) : null,
        isPending: isIdle || isPending,
        isError: isError,
        isSuccess: isSuccess,
        error,
        forbidden: error?.status === 403,
    };
}
